import React, { useContext } from "react";
import { UIContext } from "../contexts/UIContext";
import { DataContext } from "../contexts/DataContext";
import Sidebar from "./Sidebar";
import styled from "styled-components";
import Net from "./Net";
import RightBar from "./RightBar";

const MainDiv = styled.div`
  display: flex;
`;
const Center = styled.div`
  display: flex;
  width: 100%;
`;

export default function Main() {
  const { selectedPeg } = useContext(UIContext);
  const { clearData } = useContext(DataContext);

  return (
    <MainDiv>
      <Sidebar />
      <Center>
        {/* <div
          onClick={() => {
            clearData();
          }}
        >
          CLEAR ALL DATA
        </div> */}
        {selectedPeg && <Net />}
      </Center>
      <RightBar />
    </MainDiv>
  );
}
