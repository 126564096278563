import React, { useState, useContext } from "react";
import { DataProvider } from "./contexts/DataContext";
import { UIProvider } from "./contexts/UIContext";

import Main from "./components/Main";
import { ControlProvider, ControlContext } from "./contexts/ControlContext";
import Mobile from "./components/Mobile";
import Header from "./components/Header";
import styled, { ThemeProvider } from "styled-components";
import DisplayLeaderboard from "./components/DisplayLeaderboard";
import LeaderboardHeader from "./components/LeaderboardHeader";
import Splash from "./components/Splash";
import AllPegs from "./components/AllPegs";
import useLocalStorage from "./useLocalStorage";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const PopupMain = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
  z-index: 100;
`;
const PopupMessage = styled.div`
  font-size: 2em;
  color: #d61c47;
`;

const Background = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const light = {
  name: "light-theme",
  colors: {
    background: "hsl(0, 0%, 100%)",
    peg_background: "#e1e1e1",
    text: "hsl(0, 1%, 16%)",
  },
};

export const dark = {
  name: "dark-theme",
  colors: {
    background: "hsl(0, 1%, 16%)",
    peg_background: "#373a4c",
    text: "hsl(0, 0%, 100%)",
  },
};

var urlParams = new URLSearchParams(window.location.search);

function Popup() {
  const { connected } = useContext(ControlContext);
  if (connected) {
    return <div />;
  }
  return (
    <PopupMain>
      <PopupMessage>Trying to connect to the server...</PopupMessage>
    </PopupMain>
  );
}

function App() {
  const [selectedTheme, setSelectedTheme] = useLocalStorage(
    "fisho_theme",
    "light"
  );
  let website = window.location.hostname;
  let mob = urlParams.get("mobile");
  window.splash = urlParams.get("splash") === "true" || false;
  window.showHeader = urlParams.get("header") !== "false" || false;
  window.device = urlParams.get("device") || "Server";
  window.tournament = urlParams.get("tourn") || 1;
  window.pegs = urlParams
    .get("pegs")
    ?.split(",")
    ?.map((p) => parseInt(p));
  window.leaderboard = urlParams.get("leaderboard") || 0;
  window.page = urlParams.get("page") || 0;
  const [mobile, setMobile] = useState(mob === "true" || false);
  if (window.leaderboard) {
    document.body.style.backgroundColor = "#dddddd";
  }

  const HandleThemeChange = (theme) => {
    setSelectedTheme(theme);
  };

  return (
    <ThemeProvider theme={selectedTheme === "light" ? light : dark}>
      <Container>
        <ControlProvider>
          <DataProvider>
            <UIProvider>
              <div></div>
              {window.splash === true && <Splash />}
              {!window.splash && (
                <>
                  {window.leaderboard == 0 && <Popup />}
                  {!mobile &&
                    window.leaderboard == 0 &&
                    website.indexOf("herokuapp") === -1 &&
                    website.indexOf("hyperstudios") === -1 && (
                      <>
                        <Background>
                          <Header />
                          <Main></Main>
                        </Background>
                      </>
                    )}
                  {mobile &&
                    website.indexOf("herokuapp") === -1 &&
                    website.indexOf("hyperstudios") === -1 &&
                    window.leaderboard == 0 &&
                    parseInt(window.device) > 0 && (
                      <Mobile HandleThemeChange={HandleThemeChange}></Mobile>
                    )}

                  {mobile &&
                    website.indexOf("herokuapp") === -1 &&
                    website.indexOf("hyperstudios") === -1 &&
                    window.leaderboard == 0 &&
                    !parseInt(window.device) && <AllPegs />}
                  {window.leaderboard == 1 && (
                    <>
                      {window.showHeader && <LeaderboardHeader />}
                      <DisplayLeaderboard>Leaderboard</DisplayLeaderboard>
                    </>
                  )}
                </>
              )}
            </UIProvider>
          </DataProvider>
        </ControlProvider>
      </Container>
    </ThemeProvider>
  );
}

export default App;
