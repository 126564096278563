import React, { useContext, useState } from "react";
import { DataContext } from "../contexts/DataContext";
import Numpad from "./Numpad";
import styled from "styled-components";
const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

const Weight = styled.div`
  font-size: 5em;
  font-weight: 400;
  padding: 0.5em;
  display: flex;
`;
const Small = styled.div`
  font-weight: 100;
  margin-left: 0.2em;
`;
const Buttons = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 0.5em;
`;
const SaveButton = styled.div`
  width: 40%;
  height: 80px;
  border: 1px solid #252734;
  background-color: #18da8e;
  color: #000;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 2em;
`;
const CancelButton = styled.div`
  width: 40%;
  height: 80px;
  border: 1px solid #252734;
  background-color: #da1849;
  color: #fff;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 2em;
`;

export default function DataEntry({ initialWeight, onCancel, onSave }) {
  const { data, addFish } = useContext(DataContext);
  const [weight, setWeight] = useState(
    initialWeight ? initialWeight.toString() : ""
  );
  return (
    <Main>
      <Buttons>
        <CancelButton onClick={onCancel}>Cancel</CancelButton>
        <SaveButton
          onClick={() => {
            if (weight != "") {
              onSave(weight);
            }
          }}
        >
          Save
        </SaveButton>
      </Buttons>
      <Weight>
        {weight}
        <Small>kg</Small>
      </Weight>
      <Numpad
        onKeyPressed={(key) => {
          if (key === "CLR") {
            setWeight("");
          } else if (key === "DEL") {
            setWeight(weight.slice(0, -1));
          } else {
            setWeight(weight + key);
          }
        }}
      />
    </Main>
  );
}
