import React, { useState, useEffect } from "react";

const ControlContext = React.createContext([{}, () => {}]);

const ControlProvider = (props) => {
  const [ws, setWS] = useState(null);
  const [connected, setConnected] = useState(false);
  const [devices, setDevices] = useState([]);
  const [leaderboard, setData] = useState({ data: [] });
  const [fullData, setFullData] = useState([]);
  const [tournament, setTournament] = useState(window.tournament);

  function connect() {
    console.log("CG connecting to server");

    setWS(
      new WebSocket(
        window.location.origin.indexOf("localhost") > -1
          ? window.location.origin.replace(/^http/, "ws").replace(3001, 6363)
          : window.location.origin.indexOf("hyper") > -1
          ? window.location.origin
              .replace("https", "http")
              .replace(/^http/, "wss")
          : window.location.origin.replace(/^http/, "ws")
      )
    );
  }

  useEffect(() => {
    connect();
  }, []);

  useEffect(() => {
    if (tournament && fullData.length > tournament - 1) {
      setData(fullData[tournament - 1]);
    }
  }, [fullData, tournament]);

  useEffect(() => {
    if (ws) {
      let interval;
      let timeout;
      ws.onopen = function open() {
        setConnected(true);
        clearInterval(interval);
        interval = setInterval(() => {
          ws.send(
            JSON.stringify({
              type: "ping",
              time: Date.now(),
              device: window.device,
              tournament: window.tournament,
            })
          );
        }, 10000);
        sendData({
          type: "connected",
          device: window.device,
          tournament: window.tournament,
        });
      };

      ws.onmessage = (data) => {
        let obj = JSON.parse(data.data);
        console.log(obj);

        switch (obj.type) {
          case "devices":
            setDevices(obj.data);
            break;
          case "data":
            setFullData(obj.data);
            setData(obj.data[tournament - 1]);
            break;
          default:
        }
      };
      ws.onerror = (err) => {
        console.log("CG on message");
        ws.close();
      };
      ws.onclose = (data) => {
        setConnected(false);
        console.log("CG on close");
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          connect();
        }, 1000);
      };

      return () => {
        clearTimeout(timeout);
        clearInterval(interval);
      };
    }
  }, [sendData, tournament, ws]);

  function sendData(data) {
    if (ws) {
      console.log(data);
      data.group = "FISH";
      data.device = window.device;
      ws.send(JSON.stringify(data));
    }
  }

  return (
    <ControlContext.Provider
      value={{
        sendData,
        connected,
        devices,
        leaderboard,
        tournament,
        setTournament,
      }}
    >
      {props.children}
    </ControlContext.Provider>
  );
};

export { ControlContext, ControlProvider };
