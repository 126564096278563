import React, { useContext, useState } from "react";
import styled from "styled-components";
import { DataContext } from "../contexts/DataContext";
import { UIContext } from "../contexts/UIContext";
import { MdCancel, MdChevronLeft } from "react-icons/md";
import DataEntry from "./DataEntry";

const Main = styled.div`
  width: 100%;
  padding-top: 1em;
  height: 100vh;
`;

const Row = styled.div`
  padding: 0.5em;
  border-bottom: 1px solid #353748;
  width: 100%;
  display: flex;
  align-items: center;
  :hover {
    background-color: #6d2d3e;
  }
`;

const WeightInput = styled.input`
  background-color: #16181f;
  border: none;
  padding: 0.3em;
  padding-left: 1em;
  width: 200px;
  box-sizing: border-box;
  font-size: 1.2em;
  outline: none;
  color: #d0d0d0;
  border-radius: 4px;
`;
const InputControl = styled.div`
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex;
`;
const FishList = styled.div`
  margin-top: 1em;
  margin-bottom: 1em;
`;
const Button = styled.div`
  width: 80px;
  height: 50px;
  padding: 0.5em;
  border-radius: 4px;
  text-align: center;
  margin-left: 1em;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.peg_background};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.div`
  color: #db0741;
  margin-right: 0.5em;
  font-size: 1.2em;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const WeighIns = styled.div`
  display: flex;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  border-bottom: solid 1px #5f5f5f;
  padding-bottom: 1em;
  justify-content: center;
`;
const Name = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.8em;
`;

const WeighIn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: ${(props) => (props.hasData ? "2em" : "4em")};
  background-color: ${({ theme }) => theme.colors.peg_background};
  border-radius: 4px;
  width: 45%;
  height: 150px;
  box-sizing: border-box;
  margin: 0.2em;
  opacity: ${(props) => (props.hasData ? ".5" : "1")};
`;
const WeighInTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.5em;
  font-weight: 100;
`;

const WeighInContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
`;
const Column = styled.div`
  width: 25%;
`;
const CenterColumn = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
`;
const WeighInNumber = styled.div`
  font-size: 1.2em;
  padding-left: 1em;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: end;
  padding-right: 10px;
`;
export default function MobileNet({
  onBack,
  data,
  addFish,
  HandleThemeChange,
}) {
  const { selectedPeg, setSelectedPeg } = useContext(UIContext);
  const [weighInNumber, setWeighInNumber] = useState();
  let weighInFish = data.fish.find((fish) => fish.weighIn === weighInNumber);
  return (
    <Main>
      <Header>
        <Column>
          {!weighInNumber && (
            <Button onClick={onBack}>
              <MdChevronLeft />
              Peg Select
            </Button>
          )}
          <WeighInNumber>
            {weighInNumber ? "WeighIn #" + weighInNumber : ""}
          </WeighInNumber>
        </Column>
        <CenterColumn>
          <Name>
            Peg {selectedPeg.number} - {selectedPeg.name.first}{" "}
            {selectedPeg.name.last.toUpperCase()}
          </Name>
        </CenterColumn>
        <Column>
          <Buttons>
            <Button
              onClick={() => {
                HandleThemeChange("dark");
              }}
            >
              DARK
            </Button>
            <Button
              onClick={() => {
                HandleThemeChange("light");
              }}
            >
              LIGHT
            </Button>
          </Buttons>
        </Column>
      </Header>
      {!weighInNumber && (
        <WeighInContainer>
          {new Array(10).fill(1).map((item, index) => {
            let caught = data.fish.find((fish) => fish.weighIn === index + 1);
            return (
              <WeighIn
                hasData={caught}
                onClick={() => {
                  setWeighInNumber(index + 1);
                }}
              >
                <WeighInTitle>Weigh-In</WeighInTitle>
                <div>#{index + 1}</div>
                <div>{caught && caught.weight + "kg"}</div>
              </WeighIn>
            );
          })}
        </WeighInContainer>
      )}
      {weighInNumber && (
        <DataEntry
          initialWeight={weighInFish ? weighInFish.weight : ""}
          onCancel={() => {
            setWeighInNumber(null);
          }}
          onSave={(weight) => {
            addFish(selectedPeg.number, weight, weighInNumber);
            setWeighInNumber(null);
          }}
        />
      )}
      {/* <InputControl>
        <WeightInput
          value={weight}
          placeholder="Fish Weight"
          type="number"
          // placeholder="1.0"
          step="0.001"
          onChange={(e) => setWeight(parseFloat(e.currentTarget.value))}
          // min="0"
          // max="10"
        ></WeightInput>
        <Button
          onClick={() => {
            if (parseFloat(weight)) {
              addFish(selectedPeg.number, weight);
            }
          }}
        >
          Add Fish
        </Button>
      </InputControl> */}
    </Main>
  );
}
