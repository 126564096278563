import React from "react";
import styled from "styled-components";
import Logo from "../images/HyperLogo.png";
const Main = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
`;
const LogoImg = styled.img`
  max-height: 20px;
`;
export default function Splash() {
  return (
    <Main>
      <LogoImg src={Logo} />
      <div>Fishing Version 4.5</div>
    </Main>
  );
}
