import React from "react";
import styled from "styled-components";

const Main = styled.div`
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
`;

const ModalContent = styled.div`
  background-color: rgb(42, 44, 57);
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  border-radius: 4px;
  width: 80%; /* Could be more or less, depending on screen size */
`;

const Close = styled.span`
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  :hover {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  :focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
`;
const Button = styled.div`
  width: 80px;
  height: 20px;
  padding: 0.5em;
  border-radius: 4px;
  text-align: center;
  margin-left: 1em;
  cursor: pointer;
  background-color: ${(props) =>
    props.red ? "red" : props.green ? "green" : ""};
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Row = styled.div`
  display: flex;
`;
export default function Modal({ onCancel, onConfirm }) {
  return (
    <Main
      onClick={() => {
        onCancel();
      }}
    >
      <ModalContent>
        <Close
          onClick={(e) => {
            e.stopPropagation();
            onCancel();
          }}
        >
          &times;
        </Close>
        <p>Are you sure you want to delete this weigh in data?</p>
        <Row>
          <Button
            red
            onClick={(e) => {
              e.stopPropagation();
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            green
            onClick={(e) => {
              e.stopPropagation();
              onConfirm();
            }}
          >
            Confirm
          </Button>
        </Row>
      </ModalContent>
    </Main>
  );
}
