import React, { useState, useContext, useEffect } from "react";

const UIContext = React.createContext([{}, () => {}]);

const UIProvider = (props) => {
  const [selectedPeg, setSelectedPeg] = useState(null);

  return (
    <UIContext.Provider value={{ selectedPeg, setSelectedPeg }}>
      {props.children}
    </UIContext.Provider>
  );
};

export { UIContext, UIProvider };
