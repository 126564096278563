import React, { useContext } from "react";
import styled from "styled-components";
import { DataContext } from "../contexts/DataContext";
import { FiChevronUp, FiChevronDown, FiChevronRight } from "react-icons/fi";

const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "870px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

const Main = styled.div`
  width: 100%;
  background-color: #dddddd;
  font-family: "OpenSans";
  display: flex;
  justify-content: center;
  padding: ${(props) => (window.showHeader ? "1em" : "")};
  box-sizing: border-box;
  color: #000;
  min-height: 100vh;
  font-size: 0.5em;
  margin-top: ${(props) => (window.showHeader ? "90px" : "")};
  @media ${device.mobileL} {
    font-size: 0.8em;
  }
  @media ${device.tablet} {
    font-size: ${(props) =>
      props.onSite && window.page == 0
        ? "1.4em"
        : window.page != 0
        ? "1.7em"
        : "1em"};
  }
`;
const Leaderboard = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  padding: ${(props) => (window.showHeader ? "1em" : "")};
  padding-top: ${(props) => (window.showHeader ? "" : "5px")};
  padding-right: 2em;
`;
const Row = styled.div`
  padding-top: ${(props) => (window.showHeader ? "0.3em" : "0.35em")};
  padding-bottom: ${(props) => (window.showHeader ? "0.3em" : "0.35em")};
  background-color: ${(props) =>
    window.showHeader === false && props.index % 2 ? "#f1f1f1" : ""};
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-color: #eee;
  display: flex;
  align-items: center;
  height: 25px;
  opacity: ${(props) => (props.faded && window.showHeader ? "0.5" : "1")};
  width: ${(props) => (props.weighIn ? "140px" : "100%")};
  justify-content: center;
  color: ${(props) => (props.faded ? "#a3a0a0" : "#222")};
  display: ${(props) =>
    window.page == 0
      ? "flex"
      : props.index <= 21 && window.page == 1
      ? "flex"
      : props.index > 21 && window.page == 2
      ? "flex"
      : "none"};
`;
const Position = styled.div`
  /* width: 150px; */
`;
const Name = styled.div`
  width: 100%;
  color: ${(props) => (props.faded ? "#a3a0a0" : "#222")};
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
  /* padding-left: 50px; */
  display: flex;
`;
const Difference = styled.div`
  width: 100%;
  text-align: right;
`;
const Weight = styled.div`
  width: 100%;
  text-align: right;
`;
const Header = styled.div`
  display: flex;
`;
const Column = styled.div`
  display: flex;
  color: #222;
  font-weight: 100;
  text-transform: uppercase;
  flex-direction: column;
  text-align: center;
  white-space: nowrap;
  align-items: ${(props) => (props.name ? "" : "center")};
  width: ${(props) =>
    props.name && window.showHeader === false
      ? "323px"
      : props.name
      ? "100%"
      : ""};
  > div {
    padding-left: 1em;
    padding-right: 1em;
  }
`;
const TabletColumn = styled.div`
  display: none;
  color: #222;
  font-weight: 100;
  text-transform: uppercase;
  flex-direction: column;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  @media ${device.laptopL} {
    display: flex;
  }
  > div {
    margin-left: 1em;
    margin-right: 1em;
  }
`;
const MobileColumn = styled.div`
  display: none;
  color: #222;
  font-weight: 100;
  text-transform: uppercase;
  flex-direction: column;
  text-align: center;
  align-items: center;

  white-space: nowrap;
  @media ${device.tablet} {
    display: flex;
  }
  > div {
    margin-left: 1em;
    margin-right: 1em;
  }
`;
const Title = styled.div`
  display: flex;
  font-weight: 700;
  margin-left: ${(props) => (props.right ? "auto" : "")};
  text-align: ${(props) =>
    props.right ? "right" : props.left ? "left" : "center"};
  padding-bottom: 10px;
`;
const First = styled.div`
  font-weight: 100;
  padding-right: 10px;
`;
const Up = styled(FiChevronUp)`
  color: green;
`;
const Down = styled(FiChevronDown)`
  color: red;
`;
const Level = styled(FiChevronRight)``;
const Circle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) => (props.winning ? "#213f97" : "")};
  margin-left: 10px;
  margin-top: 5px;
`;
export default function DisplayLeaderboard() {
  const { data } = useContext(DataContext);
  let currentWeighIn = 0;
  data.data.forEach((fisher, index) => {
    if (fisher.weighIn) {
      currentWeighIn = Math.max(currentWeighIn, fisher.weighIn);
    }
  });

  return (
    <Main onSite={window.showHeader === false}>
      <Leaderboard>
        <MobileColumn>
          <Title>+/-</Title>
          {data.data.map((fisher, index) => {
            return (
              <Row faded={fisher.weighIn < currentWeighIn} index={index}>
                {fisher.weighIn === currentWeighIn &&
                  fisher.weighIn > 1 &&
                  fisher.position < fisher.previousPosition && <Up />}
                {fisher.weighIn === currentWeighIn &&
                  fisher.weighIn > 1 &&
                  fisher.position > fisher.previousPosition && <Down />}
                {fisher.weighIn === currentWeighIn &&
                  fisher.weighIn > 1 &&
                  fisher.position === fisher.previousPosition && <Level />}
              </Row>
            );
          })}
        </MobileColumn>
        <Column>
          <Title>Pos</Title>
          {data.data.map((fisher, index) => {
            return (
              <Row faded={fisher.weighIn < currentWeighIn} index={index}>
                <Position>{fisher.position}</Position>
              </Row>
            );
          })}
        </Column>
        <TabletColumn>
          <Title>Prev</Title>
          {data.data.map((fisher, index) => {
            return (
              <Row faded={fisher.weighIn < currentWeighIn} index={index}>
                {fisher.weighIn > 1 && (
                  <Position>{fisher.previousPosition}</Position>
                )}
              </Row>
            );
          })}
        </TabletColumn>

        <Column name>
          <Title name left>
            Name
          </Title>
          {data.data.map((fisher, index) => {
            return (
              <Row faded={fisher.weighIn < currentWeighIn} index={index}>
                <Name faded={fisher.weighIn < currentWeighIn}>
                  <First>{fisher.name.first}</First>
                  {fisher.name.last}
                </Name>
              </Row>
            );
          })}
        </Column>

        <TabletColumn>
          <Title>Section POS</Title>
          {data.data.map((fisher, index) => {
            return (
              <Row faded={fisher.weighIn < currentWeighIn} index={index}>
                {fisher.weighIn > 0 && (
                  <Position>{fisher.sectionPosition}</Position>
                )}
              </Row>
            );
          })}
        </TabletColumn>

        <Column>
          <Title>Peg</Title>
          {data.data.map((fisher, index) => {
            return (
              <Row faded={fisher.weighIn < currentWeighIn} index={index}>
                <Position>{fisher.number}</Position>
              </Row>
            );
          })}
        </Column>
        <Column>
          <Title right style={{ paddingRight: "0px" }}>
            Total Weight
          </Title>
          {data.data.map((fisher, index) => {
            return (
              <Row faded={fisher.weighIn < currentWeighIn} index={index}>
                <Weight>{fisher.totalWeight}</Weight>
              </Row>
            );
          })}
        </Column>

        <Column>
          <Title>Weigh-In</Title>
          {data.data.map((fisher, index) => {
            return (
              <Row
                faded={fisher.weighIn < currentWeighIn}
                index={index}
                weighIn
              >
                <Position>{fisher.weighIn}</Position>
              </Row>
            );
          })}
        </Column>

        <TabletColumn>
          <Title right style={{ paddingRight: "0px", marginRight: "0px" }}>
            This Weigh-In
          </Title>
          {data.data.map((fisher, index) => {
            return (
              <Row faded={fisher.weighIn < currentWeighIn} index={index}>
                <Weight>{fisher.thisWeighInTotal}</Weight>
              </Row>
            );
          })}
        </TabletColumn>

        <MobileColumn>
          <Title right style={{ paddingRight: "0px", marginRight: "0px" }}>
            Difference
          </Title>
          {data.data.map((fisher, index) => {
            let prev = data.data[index - 1];
            let prevTotalWeight = fisher.totalWeight;
            if (prev) {
              prevTotalWeight = prev.totalWeight;
            }
            return (
              <Row faded={fisher.weighIn < currentWeighIn} index={index}>
                <Difference>
                  {index === 0 || !fisher.totalWeight
                    ? ""
                    : (prevTotalWeight - fisher.totalWeight).toFixed(3)}
                </Difference>
              </Row>
            );
          })}
        </MobileColumn>
      </Leaderboard>
    </Main>
  );
}
