import React from "react";
import styled from "styled-components";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.peg_background};
`;

const Row = styled.div`
  width: 100%;
  display: flex;
`;
const Button = styled.div`
  width: calc(100% / 3);
  height: 120px;
  border: 1px solid #252734;
  background-color: ${({ theme }) => theme.colors.peg_background};
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 2em;
`;
export default function Numpad({ onKeyPressed }) {
  return (
    <Main>
      <Row>
        <Button></Button>
        <Button onClick={() => onKeyPressed("CLR")}>CLR</Button>
        <Button onClick={() => onKeyPressed("DEL")}>DEL</Button>
      </Row>
      <Row>
        <Button onClick={() => onKeyPressed("7")}>7</Button>
        <Button onClick={() => onKeyPressed("8")}>8</Button>
        <Button onClick={() => onKeyPressed("9")}>9</Button>
      </Row>
      <Row>
        <Button onClick={() => onKeyPressed("4")}>4</Button>
        <Button onClick={() => onKeyPressed("5")}>5</Button>
        <Button onClick={() => onKeyPressed("6")}>6</Button>
      </Row>
      <Row>
        <Button onClick={() => onKeyPressed("1")}>1</Button>
        <Button onClick={() => onKeyPressed("2")}>2</Button>
        <Button onClick={() => onKeyPressed("3")}>3</Button>
      </Row>
      <Row>
        <Button></Button>
        <Button onClick={() => onKeyPressed("0")}>0</Button>
        <Button onClick={() => onKeyPressed(".")}>.</Button>
      </Row>
    </Main>
  );
}
