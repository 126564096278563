import React from "react";
import styled from "styled-components";
import NextGenImage from "../images/NXTGEN.jpg";
import FishOImage from "../images/FishOMania-2021-logo.jpg";
const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "870px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

const Header = styled.div`
  height: 90px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  position: fixed;
  width: 100vw;
  background-color: #fff;
  display: flex;
  align-items: center;
`;
const Middle = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 1140px;
  display: flex;
  align-items: center;
`;

const FishLogo = styled.img`
  max-width: 300px;
`;

const Links = styled.div`
  margin-left: auto;
  display: none;
  @media ${device.tablet} {
    max-width: 800px;
    display: flex;
  }
`;

const Link = styled.div`
  font-size: 16px;
  display: inline-block;
  position: relative;
  padding: 8px 20px !important;
  border-right: 1px solid #d4d4d4;
  text-transform: uppercase;
  color: #111;
  cursor: pointer;
  color: ${(props) => (props.selected ? "#fff" : "")};
  background-color: ${(props) => (props.selected ? "rgb(35,63,148,1)" : "")};
  :hover {
    background-color: ${(props) =>
    props.selected ? "rgb(35,63,148,1)" : "#f4d300"};
  }
  > {
    a:link {
      color: #111;
      text-decoration: none;
    }

    a:visited {
      color: #111;
      text-decoration: none;
    }

    a:hover {
      color: #111;
      text-decoration: none;
    }

    a:active {
      color: #111;
      text-decoration: none;
    }
  }
`;

export default function LeaderboardHeader() {
  return (
    <Header>
      <Middle>
        <a href="http://www.fishomania.net/">
          <FishLogo src={window.tournament == 1 ? FishOImage : NextGenImage} />
        </a>
        <Links>
          <Link>
            <a href="http://www.fishomania.net/">HOME</a>
          </Link>
          <Link>
            <a href="http://www.fishomania.net/event/">EVENT</a>
          </Link>
          <Link>
            <a href="http://www.fishomania.net/tickets/">TICKETS</a>
          </Link>
          <Link>
            <a href="http://www.fishomania.net/category/news/">NEWS</a>
          </Link>
          <Link>
            <a href="http://www.fishomania.net/gallery/">GALLERY</a>
          </Link>
          <Link>
            <a href="http://www.fishomania.net/history/">HISTORY</a>
          </Link>
        </Links>
      </Middle>
    </Header>
  );
}
