import React, { useContext } from "react";
import styled from "styled-components";
import { DataContext } from "../contexts/DataContext";
import { UIContext } from "../contexts/UIContext";
import Leaderboard from "./Leaderboard";

const Main = styled.div`
  width: 600px;
  height: 100vh;
  background-color: #2a2c39;
  box-shadow: 3px 0px 15px 0 rgba(16, 18, 21, 0.15);
  right: 0px;
`;
const Title = styled.div`
  padding: 0.5em;
  border-bottom: 1px solid #2a2c39;
  cursor: pointer;
  border-right: 1px solid #2a2c39;
  background-color: #1f212b;
  border-bottom: 1px solid #db0a41;
`;
export default function RightBar() {
  const { data } = useContext(DataContext);
  const { selectedPeg, setSelectedPeg } = useContext(UIContext);

  return (
    <Main>
      <Title>Leaderboard</Title>
      <Leaderboard></Leaderboard>
    </Main>
  );
}
