import React, { useState, useEffect, useContext } from "react";
import { ControlContext } from "./ControlContext.js";

const DataContext = React.createContext([{}, () => {}]);

const DataProvider = (props) => {
  const [data, setData] = useState({ data: [] });
  const { leaderboard, sendData } = useContext(ControlContext);

  useEffect(() => {
    setData(leaderboard);
  }, [leaderboard]);

  function addFish(peg, weight, weighIn) {
    sendData({
      type: "addFish",
      data: { peg, weight, weighIn, tournament: window.tournament },
    });
  }

  function clearData() {
    sendData({
      type: "clearData",
      data: { tournament: 1 },
    });
  }
  function clearWeighIn({ number, weighIn }) {
    sendData({
      type: "clearWeighIn",
      data: { tournament: window.tournament, number, weighIn },
    });
  }

  return (
    <DataContext.Provider
      value={{ data, addFish, setData, clearData, clearWeighIn }}
    >
      {props.children}
    </DataContext.Provider>
  );
};

export { DataContext, DataProvider };
