import React, { useContext } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { DataContext } from "../contexts/DataContext";
import { UIContext } from "../contexts/UIContext";

const Main = styled.div`
  min-width: 300px;
  height: 100vh;
  background-color: #2a2c39;
  box-shadow: 3px 0px 15px 0 rgba(16, 18, 21, 0.15);
`;
const Title = styled.div`
  padding: 0.5em;
  border-bottom: 1px solid #2a2c39;
  cursor: pointer;
  border-right: 1px solid #2a2c39;
  background-color: #1f212b;
  border-bottom: 1px solid #db0a41;
`;
const Peg = styled.div`
  padding: 0.4em;
  border-bottom: 1px solid #252734;
  display: flex;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? "#db0b41" : "")};
  :hover {
    background-color: #db0b41;
  }
`;
const Number = styled.div`
  width: 40px;
`;
export default function Sidebar() {
  const { data } = useContext(DataContext);
  const { selectedPeg, setSelectedPeg } = useContext(UIContext);

  return (
    <Main>
      <Title>Pegs</Title>
      {[...data.data]
        .sort((a, b) => a.number - b.number)
        .map((peg) => {
          return (
            <Peg
              key={peg.number}
              selected={selectedPeg === peg}
              onClick={() => {
                setSelectedPeg(peg);
              }}
            >
              <Number>{peg.number}</Number>
              <div>
                {peg.name.first} {peg.name.last.toUpperCase()}
              </div>
            </Peg>
          );
        })}
    </Main>
  );
}
