import React, { useContext, useState } from "react";
import styled from "styled-components";
import { DataContext } from "../contexts/DataContext";
import { UIContext } from "../contexts/UIContext";
import { MdCancel } from "react-icons/md";
import Modal from "./Modal";

const Main = styled.div`
  padding: 1em;
`;

const Row = styled.div`
  padding: 0.5em;
  border-bottom: 1px solid #353748;
  width: 100%;
  display: flex;
  align-items: center;
  :hover {
    background-color: #6d2d3e;
  }
`;

const WeightInput = styled.input`
  background-color: #16181f;
  border: none;
  padding: 0.3em;
  padding-left: 1em;
  width: 200px;
  box-sizing: border-box;
  font-size: 1.2em;
  outline: none;
  color: #d0d0d0;
  border-radius: 4px;
`;
const InputControl = styled.div`
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex;
`;
const FishList = styled.div`
  margin-top: 1em;
  margin-bottom: 1em;
`;
const Button = styled.div`
  width: 80px;
  height: 20px;
  padding: 0.5em;
  border-radius: 4px;
  text-align: center;
  margin-left: 1em;
  cursor: pointer;
  background-color: #db0741;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.div`
  color: #db0741;
  margin-right: 0.5em;
  font-size: 1.2em;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const Column = styled.div`
  width: 200px;
`;
const WeighIns = styled.div`
  display: flex;
`;

export default function Net() {
  const { data, addFish, clearWeighIn } = useContext(DataContext);
  const { selectedPeg, setSelectedPeg } = useContext(UIContext);
  const [weight, setWeight] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [seletctedFish, setSelectedFish] = useState();
  return (
    <Main>
      <h1>
        {selectedPeg.number} - {selectedPeg.name.first}{" "}
        {selectedPeg.name.last.toUpperCase()}
      </h1>
      {/* <WeighIns>
        {new Array(10).fill(1).map((item, index) => {
          return <div>{index + 1}</div>;
        })}
      </WeighIns> */}
      {/* <InputControl>
        <WeightInput
          value={weight}
          placeholder="Fish Weight"
          type="number"
          // placeholder="1.0"
          step="0.001"
          onChange={(e) => setWeight(parseFloat(e.currentTarget.value))}
          // min="0"
          // max="10"
        ></WeightInput>
        <Button
          onClick={() => {
            if (parseFloat(weight)) {
              addFish(selectedPeg.number, weight);
            }
          }}
        >
          Add Fish
        </Button>
      </InputControl> */}

      <div>Whats in the net..</div>
      <FishList>
        {data?.data
          ?.find((d) => d.number === selectedPeg?.number)
          ?.fish.map((fish, index) => {
            let date = new Date(fish.time);
            return (
              <Row>
                {/* <Icon>
                <MdCancel></MdCancel>
              </Icon> */}
                <Column>Fish {index + 1}</Column>
                <Column>Weight {fish.weight}kg</Column>
                <Column>
                  {date.getHours() +
                    ":" +
                    date.getMinutes() +
                    ":" +
                    date.getSeconds()}
                </Column>
                <Column>Weigh In {fish.weighIn}</Column>
                <Column>
                  <Button
                    onClick={() => {
                      setSelectedFish(fish);
                      setShowModal(true);
                    }}
                  >
                    Delete
                  </Button>
                </Column>
              </Row>
            );
          })}
        {showModal && (
          <Modal
            onCancel={() => {
              setShowModal(false);
            }}
            onConfirm={() => {
              clearWeighIn({
                weighIn: seletctedFish.weighIn,
                number: selectedPeg.number,
              });
              setShowModal(false);
            }}
          />
        )}
      </FishList>
    </Main>
  );
}
