import React, { useContext, useState } from "react";
import { ControlContext } from "../contexts/ControlContext";
import styled from "styled-components";
import Logo from "../images/HyperLogo.png";

const Main = styled.div`
  background-color: #2a2c39;
  width: 100%;
  height: 40px;
  box-shadow: 0 2px 15px 0 rgba(16, 18, 21, 0.15);
  display: flex;
  align-items: center;
  padding: 0.5em;
`;

const LogoImg = styled.img`
  max-height: 20px;
`;
const Devices = styled.div`
  display: flex;
  margin-right: auto;
`;
const Device = styled.div`
  background-color: ${(props) => (props.connected ? "#12ce8f" : "#ce1241")};
  color: ${(props) => (props.connected ? "#000" : "")};
  width: 35px;
  height: 35px;
  border-radius: 4px;
  box-shadow: 0 2px 15px 0 rgba(16, 18, 21, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1em;
`;
const Column = styled.div`
  width: calc(100% / 3);
  display: flex;
  justify-content: center;
`;
const Buttons = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 1em;
`;
const Button = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${(props) => (props.selected ? "#ce1241" : "#1f212b")};
  border-radius: 4px;
  padding: 0.5em;
  margin-left: 1em;
  cursor: pointer;
`;

export default function Header() {
  const { connected, devices } = useContext(ControlContext);
  const { tournament, setTournament } = useContext(ControlContext);

  return (
    <Main>
      <Column>
        <Devices>
          {new Array(4).fill(1).map((item, index) => {
            let device = null;
            if (devices) {
              device = devices.find(
                (d) =>
                  d.name == index + 1 &&
                  d.connected === true &&
                  d.tournament === "1"
              );
            }
            return <Device connected={device}>{index + 1}</Device>;
          })}
        </Devices>
        <Devices>
          {new Array(1).fill(1).map((item, index) => {
            let device = null;
            if (devices) {
              device = devices.find(
                (d) =>
                  d.name == index + 1 &&
                  d.connected === true &&
                  d.tournament === "2"
              );
            }
            return <Device connected={device}>{index + 1}</Device>;
          })}
        </Devices>
      </Column>
      <Column>
        <LogoImg src={Logo} />
      </Column>
      <Column>
        <Buttons>
          <Button
            selected={tournament == "2"}
            onClick={() => {
              window.tournament = "2";
              setTournament("2");
            }}
          >
            NXTGEN Tournament
          </Button>
          <Button
            selected={tournament == "1"}
            onClick={() => {
              window.tournament = "1";
              setTournament("1");
            }}
          >
            Main Tournament
          </Button>
        </Buttons>
      </Column>
    </Main>
  );
}
