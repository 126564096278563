import React, { useContext } from "react";
import styled from "styled-components";
import { DataContext } from "../contexts/DataContext";
import { UIContext } from "../contexts/UIContext";

const Main = styled.div`
  width: 100%;
`;
const Row = styled.div`
  padding: 0.5em;
  border-bottom: 1px solid #252734;
  display: flex;
  align-items: center;
`;
const Position = styled.div`
  width: 40px;
`;
const Name = styled.div`
  width: 150px;
`;
const Difference = styled.div`
  width: 100px;
`;
const Weight = styled.div`
  width: 100px;
`;
export default function Leaderboard() {
  const { data } = useContext(DataContext);
  const { selectedPeg, setSelectedPeg } = useContext(UIContext);

  return (
    <Main>
      {data.data.map((fisher, index) => {
        let prev = data[index - 1];
        let prevTotalWeight = fisher.totalWeight;
        if (prev) {
          prevTotalWeight = prev.totalWeight;
        }
        return (
          <Row>
            <Position>{fisher.position}</Position>
            <Position>{fisher.number}</Position>
            {/* <Position>{fisher.position}</Position> */}
            <Name>{fisher.name.last}</Name>
            <Weight>{fisher.thisWeighIn}</Weight>
            <Weight>{fisher.totalWeight}</Weight>
            <Position>{fisher.weighIn}</Position>
            <Difference>
              {index === 0 || !fisher.totalWeight
                ? ""
                : (prevTotalWeight - fisher.totalWeight).toFixed(3)}
            </Difference>
          </Row>
        );
      })}
    </Main>
  );
}
