import React, { useContext } from "react";
import { UIContext } from "../contexts/UIContext";
import { DataContext } from "../contexts/DataContext";
import styled from "styled-components";
import MobileNet from "./MobileNet";

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;
const Pegs = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: space-around;
`;
const Peg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #373a4c;
  border-radius: 4px;
  font-size: 1.4em;
  flex-direction: column;
  padding: 0.5em;
  box-sizing: border-box;
  min-width: 20%;
  margin: 0.5em;
`;
const PegTitle = styled.div`
  font-size: 1em;
  font-weight: 100;
`;
const PegNumber = styled.div`
  font-size: 3em;
`;
const PegName = styled.div`
  font-size: 1em;
`;
const Button = styled.div`
  width: 80px;
  height: 20px;
  padding: 0.5em;
  border-radius: 4px;
  text-align: center;
  margin-left: 1em;
  cursor: pointer;
  background-color: #373a4c;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  border-bottom: solid 1px #5f5f5f;
  padding-bottom: 1em;
`;
const Info = styled.div`
  display: flex;
  align-items: center;
  font-size: 1em;
`;
export default function AllPegs({ onBack }) {
  const { selectedPeg, setSelectedPeg } = useContext(UIContext);
  const { data, addFish } = useContext(DataContext);
  let numOfPegs = window.tournament === "1" ? 6 : 8;
  let sorted = data.data.sort((a, b) => {
    return a.number - b.number;
  });

  return (
    <Main>
      {!selectedPeg && (
        <>
          <Pegs>
            {sorted.map((peg) => {
              return (
                <Peg onClick={() => setSelectedPeg(peg)} numOfPegs={numOfPegs}>
                  <PegName>
                    {peg.name.first} {peg.name.last.toUpperCase()}
                  </PegName>
                  <PegNumber>#{peg.number}</PegNumber>
                  <Info>
                    {peg.totalWeight}kg after {peg.weighIn}
                  </Info>
                </Peg>
              );
            })}
          </Pegs>
        </>
      )}
      {selectedPeg && (
        <MobileNet
          onBack={() => setSelectedPeg(null)}
          data={selectedPeg}
          addFish={(number, weight, weighInNumber) => {
            addFish(number, weight, weighInNumber);
            setSelectedPeg(null);
          }}
        />
      )}
    </Main>
  );
}
